/* React Tinongo <Accordion> component */

import React, { Component } from 'react';
import classNames from 'classnames';
import { html } from '../utils';

import styles from './accordion.module.scss';

/* __<AccordionItem> component__ */
class AccordionItem extends Component {
  /**
   * Constructor:
   * Initializes state and binds methods.
   */
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ active: !this.state.active });
  }

  render() {
    const classes = classNames({
      [`${styles.accordionItemActive}`]: this.state.active,
    });

    return (
      <li className={classes} data-accordion-item>
        <button
          className={styles.accordionToggle}
          aria-expanded={this.state.active}
          aria-controls={this.props.id}
          onClick={this.toggle}>
          <svg
            className={styles.accordionToggleIcon}
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fillRule="evenodd">
            <path d="M0 10.6L4.7 6 0 1.4 1.4 0l6.1 6-6.1 6z"></path>
          </svg>
          <h2 className={styles.accordionItemTitle}>
            {this.props.value.frage}
          </h2>
        </button>
        <div
          id={this.props.id}
          className={`bodyText ${styles.accordionItemContent}`}
          dangerouslySetInnerHTML={html(this.props.value.antwort.antwort)}
        />
      </li>
    );
  }
}

/* <Accordion> component */
function Accordion(props) {
  const classes = classNames({
    [`${styles.accordion}`]: true,
  });

  const accordionItems = props.items.map((item) => (
    <AccordionItem key={item.id} id={item.id} value={item} />
  ));

  return (
    <ul className={classes} data-accordion>
      {accordionItems}
    </ul>
  );
}

export default Accordion;
