//
// Gatsby Tinongo FAQ page
// Route: /faq
//

import React from 'react';
import { graphql } from 'gatsby';

import AppShell from '../components/app-shell';
import Content from '../components/content';
import Section from '../components/section';
import Accordion from '../components/accordion';
import SEO from '../components/seo';

export default function Faq({ data }) {
  const header = (
    <header>
      <h1 className="pageTitle">Fragen &amp; Antworten</h1>
    </header>
  );

  const faqGeneral = (
    <Section title="Frage allgemein" withDivider>
      <Accordion items={data.fragenAllgemein.nodes} />
    </Section>
  );

  const faqTinongo = (
    <Section title="Frage zu Tinongo" withDivider>
      <Accordion items={data.fragenZuTinongo.nodes} />
    </Section>
  );

  const faqSports = (
    <Section title="Frage zur Wahl des Sports" withDivider>
      <Accordion items={data.fragenZurWahlDesSports.nodes} />
    </Section>
  );

  return (
    <>
      <SEO title="Häufig gestellte Fragen" />
      <AppShell>
        <Content>
          <article>
            {header}
            <div>
              {data.fragenAllgemein.nodes.length > 0 && faqGeneral}
              {data.fragenZuTinongo.nodes.length > 0 && faqTinongo}
              {data.fragenZurWahlDesSports.nodes.length > 0 && faqSports}
            </div>
          </article>
        </Content>
      </AppShell>
    </>
  );
}

export const query = graphql`
  query {
    fragenZuTinongo: allContentfulFaq(
      filter: { art: { eq: "Frage zu Tinongo" } }
    ) {
      nodes {
        id
        frage
        antwort {
          antwort
        }
      }
    }
    fragenAllgemein: allContentfulFaq(
      filter: { art: { eq: "Frage allgemein" } }
    ) {
      nodes {
        id
        frage
        antwort {
          antwort
        }
      }
    }
    fragenZurWahlDesSports: allContentfulFaq(
      filter: { art: { eq: "Frage zur Wahl des Sports" } }
    ) {
      nodes {
        id
        frage
        antwort {
          antwort
        }
      }
    }
  }
`;
